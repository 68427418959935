@mixin respond($breakpoint) {
  // 600px
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  }

  @if $breakpoint == tab-port {
    // 900 px
    @media only screen and (max-width: 56.25em) {
      @content;
    }
  }

  @if $breakpoint == tab-land {
    // 1200px
    @media only screen and (max-width: 75em) {
      @content;
    }
  }

  @if $breakpoint == big-desk {
    // 1800px
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
}

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
