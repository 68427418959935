.nav {
  background-color: $color-navy;
  font-family: $head-text;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem;
    padding: 0;
  }

  ul li {
    list-style: none;
    text-align: center;
    width: 20%;
  }

  ul li a {
    color: $color-pale-yellow;
    display: block;
    font-size: 2rem;
    padding: 1rem 1.5rem;
    position: relative;
    text-decoration: none;

    @include respond(phone) {
      justify-content: space-between;
      padding: 0rem 0rem;
    }
  }

  ul li a:hover {
    background-color: #02243a;
    color: $color-pale-yellow;
    transform: scale(1.2);
  }

  ul li a:before {
    background-color: $color-pale-yellow;
    content: "";
    height: 0.2rem;
    left: 0;
    position: absolute;
    top: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.5s;
    width: 100%;
  }

  ul li a:hover:before,
  ul li a:hover:after {
    transform: scaleX(1);
  }

  ul li a:after {
    background-color: $color-pale-yellow;
    bottom: 0;
    content: "";
    height: 0.2rem;
    left: 0;
    position: absolute;

    transform: scaleX(0);
    transform-origin: right;
    transition: all 0.5s;
    width: 100%;
  }

  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    ul li a:hover {
      transform: scale(1);
    }
  }
}
