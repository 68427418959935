@import url("https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer&family=Lora&display=swap");

// COLORS
$color-footer: #655c56;
$color-white: #fff;
$color-black: #000;
$color-backgound-body: #ededed;

$color-pale-yellow: #f7f7d2;
$color-navy: #0e1e31;
$color-mint: #b9e9d5;

$color-red: red;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

// FONTS

$main-text: "Lora", serif;
$head-text: "IM Fell Great Primer", serif;

// BORDER
$border-rad: 3px;
