.footer {
  background-color: $color-navy;
  font-family: $head-text;
  font-size: 2.5rem;
  padding: 10rem;

  @include respond(tab-port) {
    padding: 8rem 0;
  }

  &__nav {
    display: inline-block;
    padding: 2rem;

    @include respond(tab-port) {
      text-align: center;
      width: 100%;
    }
  }

  &__list {
    list-style: none;

    &__item {
      display: inline-block;
      margin-top: 1rem;

      &:not(:last-child) {
        margin-right: 1.5rem;
      }

      @include respond(tab-port) {
        margin: 1rem;
      }
    }
  }

  &__a {
    &:link,
    &:visited {
      background-color: $color-pale-yellow;
      border-radius: $border-rad;
      color: $color-navy;
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.3s;
    }

    &:hover {
      box-shadow: 1.2rem 0.7rem 0.7rem $color-black;
      transform: scale(1.2);
    }
  }

  &__author {
    color: $color-pale-yellow;
    padding: 2rem;
    float: right;
    text-align: center;
    width: 80%;

    @include respond(tab-port) {
      float: none;
      width: 100%;
    }
  }
}
