*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  // This defines what 1 rem is
  font-size: 62.5%; // 1rem = 10px;
  scroll-behavior: smooth;

  // width < 1200px
  @include respond(tab-land) {
    font-size: 56.25%; // 1 rem = 9px, 9/16 = 50%
  }

  // width < 900px
  @include respond(tab-port) {
    font-size: 50%; // 1 rem = 8px, 8/16 = 50%
  }

  //width > 1200px
  @include respond(big-desk) {
    font-size: 75%; // 1 rem = 12px, 12/16
  }
}

body {
  background-color: $color-backgound-body;
  box-sizing: border-box;
  font-family: $main-text;
  padding: 1rem;

  @include respond(tab-port) {
    padding: 0;
  }
}

::selection {
  background-color: $color-pale-yellow;
  color: $color-navy;
}
