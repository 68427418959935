.about {
  height: fit-content;
  padding: 5rem;
  margin-bottom: 8rem;
  width: 100vw;

  @include respond(tab-land) {
    height: fit-content;
  }

  &__heading {
    color: black;
    font-family: $head-text;
    font-size: 7rem;
    margin: 1rem;
    text-align: center;
    text-transform: uppercase;
  }

  &__float {
    display: block;
    float: right;
    margin: 0.5rem 0.3rem;

    @include respond(phone) {
      align-items: center;
      display: flex;
      float: none;
      justify-content: center;
    }
  }

  &__float--left {
    display: block;
    float: left;
    margin: 0.5rem 0.3rem;

    @include respond(phone) {
      align-items: center;
      display: flex;
      float: none;
      justify-content: center;
    }
  }

  &__divider {
    margin-top: 5rem;
  }

  &__pic-container {
    display: inline-block;
    margin: 0.5rem 3rem;
    text-align: center;
    vertical-align: center;
  }

  &__pic {
    background: url("../../photos/about/Allison.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0.3rem;
    height: 50rem;
    width: 40rem;

    @include respond(phone) {
      border-right: 0.3rem;
      height: 40rem;
      margin: 0.5rem;
      width: 30rem;
    }
  }

  &__pic-2 {
    background: url("../../photos/about/GabeAndAllison.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0.3rem;
    height: 50rem;
    width: 40rem;

    @include respond(phone) {
      border-right: 0.3rem;
      height: 40rem;
      width: 30rem;
    }
  }

  &__pic-label {
    display: block;
    font-size: 1.5rem;
  }

  &__text {
    font-size: 2.5rem;
    height: 100%;
    hyphens: auto;
    text-indent: 5rem;
    @include respond(phone) {
      font-size: 2rem;
      text-indent: 2.5rem;
    }
  }
}
