.contact {
  height: fit-content;
  width: 100vw;

  &__container {
    width: 100%;
    display: block;
    padding: 2rem;
  }

  &__error {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    width: 100%;

    &__message {
      font-size: 3rem;
    }
  }

  &__form {
    border-radius: 3px;
    background-color: $color-navy;
    box-shadow: 1rem 1rem 1rem $color-black;
    display: block;
    margin: 3rem auto;
    padding: 1.5rem;
    width: 50%;

    @include respond(tab-port) {
      width: 100%;
    }

    &__heading {
      color: $color-navy;
      font-family: $head-text;
      font-size: 7rem;
      margin-bottom: 1rem;
      text-align: center;
    }

    &__button {
      background-color: $color-pale-yellow;
      border-radius: $border-rad;
      border-color: none;
      color: $color-navy;
      font-family: inherit;
      font-size: 2rem;
      display: block;
      margin: 3rem auto;
      transition: all 0.3s;
      width: 50%;
    }

    &__button:hover {
      box-shadow: 0.5rem 0.7rem 0.7rem $color-black;
      transform: scale(1.2);
    }

    &__button:focus {
      outline: none;
    }

    &__button:active {
      border-color: none;
      opacity: 0;
      transform: scale(0.5);
    }

    &__label {
      color: $color-pale-yellow;
      display: block;
      font-size: 1.6rem;
      font-family: inherit;
      margin: 1rem 0;
      text-align: center;
    }

    &__input {
      background-color: rgba($color-white, 0.5);
      border: none;
      border-bottom: 3px solid transparent;
      border-radius: 2px;
      color: $color-pale-yellow;
      display: block;
      font-size: 1.5rem;
      font-family: inherit;
      margin: 0 auto;
      padding: 1.5rem 2rem;
      width: 90%;

      @include respond(tab-port) {
        width: 100%;
      }

      &:focus {
        box-shadow: 0 1rem 2rem rgba($color-black, 0.1);
        border-bottom: 3px solid $color-mint;
        outline: none;
      }

      &:focus:invalid {
        border-bottom: 3px solid $color-red;
      }

      &::-webkit-input-placeholder {
        color: $color-pale-yellow;
      }
    }
  }
}
