.rates {
  background-color: $color-mint;
  border-top: 5px solid black;
  height: fit-content;
  padding: 2rem;
  position: relative;
  width: 100vw;

  &__container {
    background-color: $color-pale-yellow;
    border-radius: $border-rad;
    display: block;
    height: fit-content;
    position: relative;
    left: 0;
    top: 0;
    transform: translate(0%, 0%);
    margin: 2rem;
    margin-right: 5rem;

    @include respond(tab-port) {
      margin: 0 auto;
    }
  }

  &__heading {
    color: $color-navy;
    font-family: $head-text;
    font-size: 7rem;
    margin: 1rem;
    text-align: center;
    text-transform: uppercase;

    @include respond(tab-port) {
      margin: 1.5rem;
    }

    @include respond(phone) {
      font-size: 5rem;
      margin: -1.25rem;
    }
  }

  &__message {
    color: $color-navy;
    font-size: 2rem;
    hyphens: auto;
    margin: 1rem;
    text-align: center;

    @include respond(tab-land) {
      margin: 0;
      margin-top: 1rem;
      font-size: 3rem;
    }

    @include respond(tab-port) {
      font-size: 2.5rem;
    }

    @include respond(phone) {
      font-size: 1.6rem;
      margin: 1rem;
    }
  }
}

.conditions {
  &__heading {
    border-top: 5px solid black;
    color: $color-navy;
    font-family: $head-text;
    font-size: 3rem;
    margin: 1rem;
    text-align: center;
    text-transform: uppercase;

    @include respond(tab-land) {
      font-size: 3.5rem;
    }

    @include respond(tab-port) {
      font-size: 3rem;
      margin: 0;
    }

    @include respond(phone) {
      font-size: 2rem;
      margin: 1rem;
    }
  }

  &__message {
    color: $color-navy;
    font-size: 2rem;
    hyphens: auto;
    margin: 1rem;
    text-align: center;

    @include respond(tab-land) {
      margin: 0;
      font-size: 3rem;
    }

    @include respond(tab-port) {
      font-size: 2.5rem;
    }

    @include respond(phone) {
      font-size: 1.6rem;
      margin: 1rem;
    }
  }
}

.types {
  &__heading {
    border-top: 5px solid black;
    color: $color-navy;
    font-family: $head-text;
    font-size: 3rem;
    margin: 1rem;
    text-align: center;
    text-transform: uppercase;

    @include respond(tab-land) {
      font-size: 3.5rem;
    }

    @include respond(tab-port) {
      font-size: 3rem;
      margin: 0;
    }

    @include respond(phone) {
      font-size: 2rem;
      margin: 1rem;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 0.3fr);

    @include respond(phone) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }

  &__img {
    background: url("../../photos/about/jewel.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 80%;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    height: 25rem;
    width: 25rem;

    @include respond(tab-land) {
      grid-column: 2;
      grid-row: 2 / 4;
    }

    @include respond(phone) {
      grid-row: 1;
      grid-column: 2;
      height: 20rem;
      width: 20rem;
    }
  }

  &__img-label {
    color: $color-navy;
    font-size: 1.6rem;
    grid-row: 5;
    grid-column: 2;
    margin-left: 6rem;
    margin-top: 3rem;

    @include respond(tab-land) {
      margin-left: 7rem;
    }

    @include respond(phone) {
      grid-row: 2;
      grid-column: 2;
      margin-top: 0;
      margin-left: 4rem;
    }
  }

  &__img-text {
    color: $color-navy;
    font-size: 3rem;
    grid-column: 4 / 7;
    grid-row: 3 / 6;

    @include respond(tab-land) {
      grid-row: 2 / 6;
      font-size: 3.5rem;
    }

    @include respond(phone) {
      font-size: 2rem;
      grid-row: 2 / 3;
      grid-column: 2;
      margin-top: 3rem;
    }
  }

  &__list {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;

    @include respond(phone) {
      display: block;
    }
  }

  &__item {
    color: $color-navy;
    font-size: 1.6rem;
    hyphens: auto;
    margin: 1rem;
    padding: 1rem;

    @include respond(tab-land) {
      font-size: 2rem;
    }

    @include respond(phone) {
      font-size: 1.6rem;
    }
  }

  &__item:nth-child(1),
  &__item:nth-child(2),
  &__item:nth-child(3) {
    border-right: 3px solid black;

    @include respond(phone) {
      border-right: none;
      border-bottom: 3px solid black;
    }
  }

  &__message--2 {
    border-bottom: 3px solid black;
    padding-bottom: 1rem;
  }

  &__message--1,
  &__message--2,
  &__message--3 {
    color: $color-navy;
    font-size: 2rem;
    hyphens: auto;
    margin: 1rem;
    text-align: center;

    @include respond(tab-land) {
      font-size: 3rem;
      margin: 0;
    }

    @include respond(tab-land) {
      font-size: 2.5rem;
    }

    @include respond(phone) {
      font-size: 1.6rem;
      margin: 1rem;
    }
  }
}

.sessions {
  &__heading {
    border-top: 5px solid black;
    color: $color-navy;
    font-family: $head-text;
    font-size: 3rem;
    margin: 1rem;
    text-align: center;
    text-transform: uppercase;

    @include respond(phone) {
      font-size: 2rem;
    }
  }

  &__message {
    color: $color-navy;
    font-size: 2rem;
    hyphens: auto;
    margin: 1rem;
    text-align: center;

    @include respond(tab-land) {
      font-size: 3rem;
      margin: 0;
    }

    @include respond(tab-port) {
      font-size: 2.5rem;
    }

    @include respond(phone) {
      font-size: 1.6rem;
      margin: 1rem;
    }
  }
}
