@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  80% {
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  80% {
    transform: translateY(-20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes text-box-bg {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: rgba($color-navy, 0.9);
    border-radius: 0.3rem;
    text-align: center;
  }
}
