.header {
  background: url("../../photos/header/GreatExpectations.jpg");
  background-size: cover;
  font-family: $head-text;
  margin-top: 5.5rem;
  height: 70vh;
  width: 100vw;

  @include respond(phone) {
    margin-top: 3.5rem;
  }

  &__text-box {
    border-radius: $border-rad;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 40%;
    transform: translate(-50%, -50%);
    transition: all 3s;
  }

  &__text-box-bg {
    animation: text-box-bg 3.1s ease-in forwards;
    background-color: transparent;
  }
}
