.heading-primary {
  color: $color-pale-yellow;
  font-family: $head-text;
  margin: 3rem 5rem;
  text-transform: uppercase;

  @include respond(phone) {
    margin: 1.5rem 3.5rem;
  }

  &--main {
    animation: moveInTop 3s ease-in-out;
    display: block;
    font-size: 6rem;
    font-weight: 400;
    letter-spacing: 1.5rem;

    @include respond(phone) {
      font-size: 3rem;
      letter-spacing: 0.5rem;
    }
  }

  &--sub {
    animation: moveInBottom 3s ease-in-out;
    display: block;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 0.74rem;
    margin-top: 2rem;

    @include respond(phone) {
      font-size: 0.5rem;
      letter-spacing: 0.25rem;
    }
  }
}
