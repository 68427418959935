.services {
  height: fit-content;
  width: 100vw;

  &__box-1 {
    border-bottom: 5px $color-black solid;
    font-size: 1.5rem;
    height: 100%;
    padding: 2.5rem;
    text-align: center;
    width: 100%;

    @include respond(tab-port) {
      padding: 2rem;
    }
  }

  &__box-2 {
    background-color: $color-mint;
    border-bottom: 5px $color-black solid;
    font-size: 1.5rem;
    height: 100%;
    padding: 2.5rem;
    text-align: center;
    width: 100%;

    @include respond(tab-port) {
      padding: 2rem;
    }
  }

  &__box-3 {
    border-bottom: 5px $color-black solid;
    font-size: 1.5rem;
    height: 100%;
    padding: 2.5rem;
    text-align: center;
    width: 100%;

    @include respond(tab-port) {
      padding: 2rem;
    }
  }

  &__box-4 {
    background-color: $color-mint;
    border-bottom: 5px $color-black solid;
    font-size: 1.5rem;
    height: 100%;
    padding: 2.5rem;
    text-align: center;
    width: 100%;

    @include respond(tab-port) {
      padding: 2rem;
    }
  }

  &__icon {
    font-size: 10rem;
  }

  &__tertiary {
    font-family: $head-text;
    font-size: 5rem;
  }

  &__text {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  &__card {
    background-color: $color-navy;
    border-radius: $border-rad;
    box-shadow: 1rem 0.5rem 0.5rem $color-black;
    margin: 0 auto;
    height: 27rem;
    transition: all 0.5s;
    width: 20rem;

    &:hover {
      box-shadow: 1.2rem 0.7rem 0.7rem $color-black;
      transform: scale(1.1);
    }

    &__header {
      color: $color-pale-yellow;
      border-bottom: 1px solid $color-pale-yellow;
      font-size: large;
      margin-bottom: 1rem;
    }

    &__list {
      list-style-type: none;

      &__item {
        color: $color-pale-yellow;
        font-size: medium;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }
  }
}
